.horatable  {
  border: none;
  width: 100%;
  margin-left: 0.2rem;
  margin-right: 20px;
  border-collapse: collapse;
  line-height: 1;

  th, td {
    text-align: left;
    border-style: hidden !important;
    padding: 5px;
  }

  td:first-child {
    font-size: 130%;
    text-align: center;
  }

  tr:nth-child(even) {
     background-color: #FFF;
  }
}

.etiqueta {
  color: #777;
  padding: 0;
  width: 2.2rem;
}

.activitat-icon {
    width: $font-size-base * 1.0;
    height: $font-size-base * 1.0;
    margin-bottom: 0.2rem;
}

.vl {
    margin-left: 0.4rem;
    border-left: 0.3rem solid $primary;
}
