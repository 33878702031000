.cste {
    p {
        padding: 0;
        margin: 0;
    }

    ul {
        margin-bottom: 0;
    }

    &.any-group {
        padding-bottom: 2rem;
    }

    &.any-column {
        -ms-flex: 0 0 9rem;
        flex: 0 0 9rem;
    }
    &.any {
        font-size: 1.3 * $font-size-base;
        font-weight: bold;
        color: $primary;
        padding-right: 0.5rem;
    }
    &.edicio {
        font-weight: bold;
        color: $gray-600;
        padding-right: 0.5rem;
    }
    &.interpret {
        font-variant: small-caps;
        padding-right: 0.5rem;
    }
    &.interprets {
        font-size: 0;
        padding-right: 0.5rem;
        & span {
            font-size: $font-size-base;
        }
        .interpret {
            padding-right: 0;
        }
    }

    &.premsa-list {
        & ul {
            margin-bottom: 0.3rem;
        }
        & li {
            list-style-type: disclosure-closed;
            color: $primary;
            /*
            &:only-child {
                margin-bottom: 0.0rem;
            }
            &:not(:only-child):last-child {
                margin-bottom: 0.5rem;
            }
            */
        }
    }

    &.article-list {
        & li {
            list-style-type: disc;
            color: $gray-600;

            div {
                font-size: 0;  // to delete spaces between span
            }

            & span, a {
                font-size: $font-size-base;
                color: $gray-600;
            }
        }
    }
}