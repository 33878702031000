#map {
    width: 100%;
    height: 300px;
}

#map.mapa-general {
    height: 600px;
    z-index: 9;
}

.embed-container {
    position: relative;
}
.maps-click-text {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 1.5em;
    font-weight: bold;
    background-color: #0004;
    color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 4rem;
    padding-left: 4rem;
    border-radius: 1rem;
}
.maps-click-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 10;
}
.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
