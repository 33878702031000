// bootstrap overrides
$font-family-sans-serif:    "Lato", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Create your own map
$custom-spacers: (
  6: $spacer * 5,
  7: $spacer * 7.5,
  8: $spacer * 10,
  9: $spacer * 13,
  10: $spacer * 17,
);
// Merge the maps
$spacers: map-merge($spacers, $custom-spacers);

// add responsiveness to w-25, w-50, etc
$utilities: (
  'width': (
    property: width,
    class: w,
    responsive: true,
    values: (
      25: 25%,
      33: 33%,
      50: 50%,
      66: 66%,
      75: 75%,
      100: 100%,
      auto: auto,
    ),
  )
);

.titol {
    margin: 1rem;
    color: white;
    font-size: 2.6rem;
    text-align: start;
}
.subtitol {
    margin: 1rem;
    color: white;
    font-size: 1.8rem;
    text-align: start;
}

h1, h2, h3, h4, h5, h6 {
    color: $primary !important;
}

h1 {
    font-weight: bold !important;
    padding-bottom: 1rem !important;
    padding-top: 2rem !important;
}
h2 {
    font-weight: bold !important;
    padding-bottom: 0.5rem !important;
    padding-top: 1.5rem !important;
}
h3 {
    padding-bottom: 0.5rem !important;
    padding-top: 1.5rem !important;
}

p {
    text-align: justify;
}

/* LINKS */
a {
   text-decoration: none !important;
}
li a:hover, p a:hover {
    text-decoration: underline !important;
}
p a {
    text-decoration: underline !important;
}

h1 a, h2 a, h3 a, h4 a {
    color: $primary;
}

$navbar-dark-color:                 rgba($white, .65);
$navbar-dark-hover-color:           rgba($white, .85);
.custom-navbar-bg {
    background-color: shade-color($primary, 30%);
}
.navbar-text:hover {
    color: rgba($white, .85) !important;
}

.custom-small-caps {
    font-variant: small-caps;
}

.custom-content-title {
    //font-variant: small-caps;
    font-weight: bold;
    color: $primary;
    padding-bottom: 1.5rem;
}

.custom-social {
    border-radius: 50% !important;
}

.custom-social-facebook {
    background-color: #3b5998;
}

.social {
  height: 30px;
  width: 30px;
  display: inline-block;
}

.inline-icon {
    width: $font-size-base * 0.9;
    height: $font-size-base * 0.9;
    margin-bottom: 0.2rem;
    margin-right: 0.2rem;
}

.post-list-item-date {
    font-size: $font-size-base * 0.8;
    padding-right: 0.5rem;
}

.post-list-counter {
    color: #fff;
    background-color: $primary;
    border-radius: 6px;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.img-caption {
  background-color: $gray-200;
  color: black;
  text-align: center;
  font-size: $font-size-sm;
}

.footnotes p {
    margin-bottom: 0px;
}