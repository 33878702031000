// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "colors";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
@import "agenda";
@import "disposicions";
@import "post";
@import "st-esteve";
@import "theme";
@import "toc";
@import "mapa";
@import "icones";
@import "gallery";

// 5. Include remainder of required parts
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/offcanvas";
@import "node_modules/bootstrap/scss/placeholders";
@import "node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "node_modules/bootstrap/scss/utilities/api";

// @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
